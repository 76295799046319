import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { ApiUsersLocationsResponse, UsersLocationsParams } from 'app/model/entity/fuji-climber-management/response-api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class FujiClimberManagementService {
  informationAccount: any;
  commonObject: Common;
  private headers: HttpHeaders;
  constructor(private http: HttpClient, private dialogService: DialogService, private translateService: TranslateService) {}

  public setInformationAccount(informationAccount: any) {
    this.informationAccount = informationAccount;
    this.headers = new HttpHeaders({
      'x-api-key': this.informationAccount?.fuji_navi_api_key || '',
      'X-Skip-Interceptor': 'skip'
    });
  }

  fujiClimberUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/fuji-climber/';

  /**
   * 6-1. sendNotification
   * @param payload
   * @returns
   */
  public sendNotification(payload: any) {
    const headers = this.headers;
    return this.http.post<any>(`${this.informationAccount.fuji_navi_api_url}register-push-notification/data`, payload, {
      headers: headers
    });
    // .pipe(
    //   catchError(error => {
    //     const errorMessage =
    //       error.status == Constant.NETWORK_ERROR_CODE
    //         ? this.translateService.instant('dialog-error.error-network-api')
    //         : error.error
    //         ? error.error.message
    //         : this.translateService.instant('ticket-editor.common-error');
    //     this.dialogService.showDialog(DialogMessageComponent, {
    //       data: {
    //         title: this.translateService.instant('dialog-error.title'),
    //         text: errorMessage
    //       }
    //     });
    //     return throwError(error);
    //   })
    // );
  }

  /**
   * 6-2. getNotificationHistory
   * @param payload
   * @returns
   */
  public getNotificationHistory() {
    const headers = this.headers;
    let params = new HttpParams().set('extendedPage', 'true');
    const options = { headers, params };
    return this.http.get<any>(`${this.informationAccount.fuji_navi_api_url}list-pushed-notification`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * usersLocations
   * @param informationAccount
   * @param startDate
   * @param endDate
   * @param saleTicketId
   * @param orderDistributionId
   * @param orderId
   * @param recipientName
   * @param phoneNumber
   * @param email
   * @param nextToken
   * @returns
   */
  getUsersLocations(payload: any, nextTokenParam?: string): Observable<ApiUsersLocationsResponse> {
    if (!this.informationAccount?.fuji_navi_api_url || !this.informationAccount?.fuji_navi_api_key) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.common-error')
        }
      });
      return;
    }
    const headers = this.headers;
    let params = new HttpParams();

    // Spread existing payload params
    const requestParams: UsersLocationsParams = {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      saleTicketId: !Helper.isEmpty(payload?.saleTicketId) && payload?.saleTicketId !== '-1' ? payload?.saleTicketId.toString() : undefined,
      orderDistributionId: payload?.orderDistributionId,
      orderId: payload?.orderId,
      recipientName: payload?.recipientName,
      phoneNumber: payload?.phoneNumber,
      email: payload?.email,
      nextToken: nextTokenParam
    };

    // Add non-null params to HttpParams
    Object.entries(requestParams).forEach(([key, value]) => {
      if (value) {
        params = params.set(key, value);
      }
    });

    const options = { headers };

    const url = `${this.informationAccount?.fuji_navi_api_url}list-user-location`;
    const finalUrl = url + '?' + params.toString().replace(/%252B/g, '%2B');
    return this.http.get<ApiUsersLocationsResponse>(finalUrl, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status === Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error?.message ?? this.translateService.instant('ticket-editor.common-error');

        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getDistributionTickets
   * @param payload
   * @returns
   */
  getDistributionTickets(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    let params = new HttpParams();

    // Spread existing payload params
    const requestParams: any = {
      userId: payload?.userId,
      startDate: payload?.startDate,
      endDate: payload?.endDate,
      ticketId: !Helper.isEmpty(payload?.ticketId) ? payload?.ticketId.toString() : undefined,
      orderId: payload?.orderId,
      orderDistributionId: payload?.orderDistributionId
    };

    Object.entries(requestParams).forEach(([key, value]) => {
      if (value) {
        params = params.set(key, value as string);
      }
    });

    const options = { headers, params };
    return this.http.get<any>(`${this.informationAccount.api_url}distribution-tickets`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status === Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error?.message ?? this.translateService.instant('ticket-editor.common-error');

        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getUsersLocation
   * @param userId
   * @param nextToken
   * @returns
   */
  getUsersLocation(userId: string, nextTokenParam?: string): Observable<any> {
    if (!this.informationAccount?.fuji_navi_api_url || !this.informationAccount?.fuji_navi_api_key) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.common-error')
        }
      });
      return;
    }
    const headers = this.headers;
    const userIdParam = userId ?? null;

    let params = new HttpParams();

    if (userIdParam) {
      params = params.set('userId', userIdParam);
    }
    if (nextTokenParam) {
      params = params.set('nextToken', nextTokenParam);
    }

    const options = { headers, params };
    return this.http.get<any>(`${this.informationAccount?.fuji_navi_api_url}fetch-user-location`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getMapSetting
   * @returns
   */
  public getMapSetting(): Observable<any> {
    return this.http.get<any>(`${this.fujiClimberUrl}get-map-setting`);
  }

  /**
   * getRoute
   * @returns
   */
  public getRoute(): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(`${this.fujiClimberUrl}get-route`);
  }

  /**
   * getMountainLodge
   * @returns
   */
  public getMountainLodge(): Observable<any> {
    return this.http.get<any>(`${this.fujiClimberUrl}get-mountain-lodge`);
  }

  /**
   * getEndPoindFujiNaviApi
   * @returns
   */
  public getGoogleApiKey(): Observable<string> {
    return this.http.get(`${this.fujiClimberUrl}get-google-api-key`, {
      responseType: 'text' as 'text'
    });
  }
}
