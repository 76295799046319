import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class TicketManagerService {
  informationAccount: any;
  commonObject: Common;
  private headers: HttpHeaders;
  mapEndPointWebRefund: Map<string, string> = new Map<string, string>();

  constructor(private http: HttpClient, private dialogService: DialogService, private translateService: TranslateService) {}

  public setInformationAccount(informationAccount: any) {
    this.informationAccount = informationAccount;
  }

  /**
   * setEndPointWebRefund
   * @param endPointWebRefund
   */
  setEndPointWebRefund(endPointWebRefund: Map<string, string>) {
    this.mapEndPointWebRefund = endPointWebRefund;
    this.headers = new HttpHeaders({
      'x-api-key': this.mapEndPointWebRefund.get(Constant.NEW_API_KEY),
      'X-Skip-Interceptor': 'skip',
      'X-Error-Msg': 'error'
    });
  }

  /**
   * getTickets
   * @param companyId
   * @param appId
   * @returns
   */
  public getOrders(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    let isReserveParam;
    if (payload.isReserve == true) {
      isReserveParam = true;
    } else if (payload.isReserve == false) {
      isReserveParam = false;
    } else {
      isReserveParam = '';
    }
    const params = {
      orderId: payload.orderId,
      saleTicketId: payload.saleTicketId,
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate,
      userId: payload.userId,
      transactionId: payload.transactionId,
      isReserve: isReserveParam,
      pageNumber: payload.pageNumber
    };
    const options = { headers: headers, params: params };
    return this.http.get<any>(`${this.informationAccount.api_url}orders`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  public getOrder(orderId: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = { orderId: orderId };
    const options = { headers: headers, params: params };
    return this.http.get<any>(`${this.informationAccount.api_url}order`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  public getPlanTicket(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/ticket`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public getItemList(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate,
      status: payload.status
    };
    return this.http.get(`${this.informationAccount.api_url}reports/order`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public getCouponTicket(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/coupon`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  public reportsReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/reservation`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  /**
   * refundWeb
   * @param payload
   * @returns
   */
  public refundWeb(payload: any) {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .post<any>(`${this.informationAccount.api_url}order/refund/web`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservationPrices - API 7-1
   * @param payload
   * @returns
   */
  getReservationPrices(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}reservation-prices`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservations - API 8.1
   * @param payload
   * @returns
   */
  getReservations(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const phoneNumber = encodeURIComponent(payload.phoneNumber);
    return this.http
      .get<any>(
        `${this.informationAccount.api_url}reservations?reservationId=${payload.reservationId}&representativeName=${payload.representativeName}&phoneNumber=${phoneNumber}&email=${payload.email}&userId=${payload.userId}&reservationVerifyCode=${payload.reservationVerifyCode}&reservationStatus=${payload.reservationStatus}&orderUsageStatus=${payload.orderUsageStatus}&appIds=${payload.appId}&orderId=${payload.orderId}&ticketId=${payload.ticketId}&operatorId=${payload.operatorId}&tripId=${payload.tripId}&startDate=${payload.startDate}&endDate=${payload.endDate}&pageNumber=${payload.pageNumber}&pageLimit=${payload.pageLimit}&sortBy=${payload.sortBy}&reservationClassId=${payload.reservationClassId}&originStopName=${payload.originStopName}&destStopName=${payload.destStopName}&defaultTime=${payload.defaultTime}`,
        { headers: headers }
      )
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getReservation - API 8.2
   * @param payload
   * @returns
   */
  getReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}reservation`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * postReservation - API 8.3
   * @param payload
   * @returns
   */
  postReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .post<any>(`${this.informationAccount.api_url}reservation`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * putReservation - API 8.4
   * @param payload
   * @returns
   */
  putReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .put<any>(`${this.informationAccount.api_url}reservation`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * deleteReservation - API 8.5
   * @param payload
   * @returns
   */
  deleteReservation(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .put<any>(`${this.informationAccount.api_url}reservation/status/cancel`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getStocks - API 6.2
   * @param payload
   * @returns
   */
  getStocks(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}stocks`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * getMaximumRecord
   */
  getMaximumRecord(): Observable<number> {
    const ticketManagerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-manager';
    return this.http.get<number>(`${ticketManagerUrl}/get-maximum-record`).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getStocksCalendar - API 6.4
   * @param payload
   * @returns
   */
  getStocksCalendar(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .get<any>(`${this.informationAccount.api_url}stocks/calendar`, { params: payload, headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * API 12-5
   * @param payload
   * @returns
   */
  public surveyResult(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/anonymous-form-answer`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  /**
   * resend
   * @param payload
   * @returns
   */
  resend(payload: any) {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http.post<any>(`${this.informationAccount.api_url}order/receipt/resend`, payload, { headers: headers });
  }

  /**
   * enableRefunds
   * @param payload
   * @returns
   */
  changeRefunds(stockId: number, orderStatus: string): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    let payload = {
      stockId: stockId,
      orderStatus: orderStatus
    };
    return this.http
      .post<any>(`${this.informationAccount.api_url}enable-refunds/stock`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * distributionHistories
   * @param payload
   * @returns
   */
  public distributionHistories(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    const params = {
      appId: payload.appId,
      startDate: payload.startDate,
      endDate: payload.endDate
    };
    return this.http.get(`${this.informationAccount.api_url}reports/distribution-histories`, {
      responseType: 'blob',
      observe: 'response',
      params: params,
      headers: headers
    });
  }

  /**
   * enableRefund
   * @param payload
   * @returns
   */
  public enableRefund(payload: any) {
    const headers = new HttpHeaders().set('x-api-key', `${this.informationAccount.api_key}`);
    return this.http
      .post<any>(`${this.informationAccount.api_url}enable-refund`, payload, { headers: headers })
      .pipe(
        catchError(error => {
          const errorMessage =
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('dialog-error.error-network-api')
              : error.error
              ? error.error.message
              : this.translateService.instant('ticket-editor.common-error');
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: errorMessage
            }
          });
          return throwError(error);
        })
      );
  }

  /**
   * API 12-5
   * @param payload
   * @returns
   */
  public loadRefundList(output: string): Observable<any> {
    if (!this.mapEndPointWebRefund) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.common-error')
        }
      });
      return throwError(() => new Error('No design environment'));
    }
    const headers = this.headers;
    const params = { output: output };

    return this.http.get(
      `${this.mapEndPointWebRefund.get(Constant.NEW_API_URL_BASE)}/${this.mapEndPointWebRefund.get(
        Constant.NEW_API_URL_STAGE
      )}/quicktrip-integration/read-refund-list`,
      {
        params: params,
        headers: headers
      }
    );
  }

  /**
   * Create Refund List
   * @param payload
   * @returns
   */
  public createRefundList(payload: any) {
    if (!this.mapEndPointWebRefund) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.common-error')
        }
      });
      return throwError(() => new Error('No design environment'));
    }
    const headers = new HttpHeaders({
      'X-Skip-Interceptor': 'skip',
      'X-Error-Msg': 'error'
    });

    return this.http.post<any>(
      `${this.mapEndPointWebRefund.get(Constant.NEW_API_URL_BASE)}/${this.mapEndPointWebRefund.get(
        Constant.NEW_API_URL_STAGE
      )}/quicktrip-integration/create-refund-list`,
      payload,
      { headers: headers }
    );
  }

  getApi41(params: any): Observable<any> {
    return this.http.post('/api/4-1', params);
  }

  getApi171(
    informationAccount: any,
    userId: string,
    startDate: string,
    endDate: string,
    ticketId: string,
    parentOrderId: string,
    childOrderId: string
  ): Observable<any> {
    const headers = new HttpHeaders().set('x-api-key', `${informationAccount.api_key}`);
    const userIdParam = userId ?? null;
    const startDateParam = startDate ?? null;
    const endDateParam = endDate ?? null;
    const ticketIdParam = ticketId ?? null;
    const parentOrderIdParam = parentOrderId ?? null;
    const childOrderIdParam = childOrderId ?? null;
    let params = new HttpParams();

    if (userIdParam) {
      params = params.set('userId', userIdParam);
    }
    if (startDateParam) {
      params = params.set('startDate', startDateParam);
    }
    if (endDateParam) {
      params = params.set('endDate', endDateParam);
    }
    if (ticketIdParam) {
      params = params.set('ticketId', ticketIdParam);
    }
    if (parentOrderIdParam) {
      params = params.set('parentOrderId', parentOrderIdParam);
    }
    if (childOrderIdParam) {
      params = params.set('childOrderId', childOrderIdParam);
    }
    const options = { headers, params };
    return this.http.get<any>(`${informationAccount.api_url}distribution-tickets`, options).pipe(
      catchError(error => {
        const errorMessage =
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : error.error
            ? error.error.message
            : this.translateService.instant('ticket-editor.common-error');
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: errorMessage
          }
        });
        return throwError(error);
      })
    );
  }

  /**
   * getEndPointWebRefund
   * @returns
   */
  getEndPointWebRefund(): Observable<Map<string, string>> {
    const ticketManagerUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/ticket-manager';
    return this.http.get<Map<string, string>>(`${ticketManagerUrl}/get-end-point-web-refund`);
  }
}
