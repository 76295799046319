import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { APIDetails } from 'app/model/entity/api-details';
import { OpenWeatherConfigParam } from 'app/model/entity/open-weather-config-params';
import { OpenWeatherContentDetail } from 'app/model/entity/open-weather-content-detail';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from './common.service';
import { DialogService } from './dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';

@Injectable({
  providedIn: 'root'
})
export class WeatherContentDetailService {
  /**
   * URL for weather-content-detail API
   */
  private readonly weatherContentDetailUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/weather-content-details';

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content details by content id
   *
   * @param contentId
   */
  getWeatherContentDetailsByContentId(contentId: Number): Observable<WeatherContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.get<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/get-weather-content-details-by-content-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content details by content id
   *
   * @param contentId
   */
  getOpenWeatherContentDetailsByContentId(contentId: Number, APIDetails: APIDetails): Observable<OpenWeatherContentDetail[]> {
    const params = new HttpParams().set('contentId', contentId + '');
    const apidetails = APIDetails ? JSON.stringify(APIDetails) : null;
    return this.http.post<OpenWeatherContentDetail[]>(
      `${this.weatherContentDetailUrl}/get-open-weather-content-details-by-content-id`,
      apidetails,
      {
        params
      }
    );
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save weather content details
   *
   * @param contentId
   */
  saveWeatherContentDetail(weatherContentDetails: WeatherContentDetail[], contentId: Number) {
    const params = new HttpParams().set('contentId', contentId + '');
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/save-weather-content-details`, weatherContentDetails, {
      params
    });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * save weather content details
   *
   * @param contentId
   */
  saveOpenWeatherContentDetail(weatherContentDetails: OpenWeatherContentDetail[], contentId: Number, APIDetails: APIDetails) {
    let payload = {
      weatherContentDetails: weatherContentDetails,
      contentId: contentId,
      apiDetailString: APIDetails ? JSON.stringify(APIDetails) : null
    };
    return this.http.post<OpenWeatherContentDetail[]>(`${this.weatherContentDetailUrl}/save-open-weather-content-details`, payload);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param weatherContentDetails
   */
  reloadWeatherContentDetails(weatherContentDetails: WeatherContentDetail[]) {
    return this.http.post<WeatherContentDetail[]>(`${this.weatherContentDetailUrl}/reload-weather-content-details`, weatherContentDetails);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * reload data
   *
   * @param weatherContentDetails
   */
  reloadOpenWeatherContentDetails(weatherContentDetails: OpenWeatherContentDetail[], APIDetails: APIDetails) {
    const params = new HttpParams().set('apiDetails', APIDetails ? JSON.stringify(APIDetails) : null + '');
    return this.http.post<OpenWeatherContentDetail[]>(
      `${this.weatherContentDetailUrl}/reload-open-weather-content-details`,
      weatherContentDetails,
      { params }
    );
  }

  /**
   * callApiAWS
   * @param method
   * @param accountId
   * @param requestBody
   * @returns
   */
  callApiAWS(method: string, accountId: string, requestBody?: OpenWeatherConfigParam) {
    const params = new HttpParams().set('method', method).set('accountId', accountId);
    if (method === 'get') {
      return this.http.get<any>(`${this.weatherContentDetailUrl}/call-api-aws`, { params });
    } else if (method === 'post' && requestBody) {
      return this.http.post<any>(`${this.weatherContentDetailUrl}/call-api-aws`, requestBody, { params });
    } else {
      throw new Error('Invalid method or missing request body');
    }
  }

  callApiAwsEntry(method: string, accountId: string, outputFile: string, autoUpdate?: boolean) {
    const params = new HttpParams()
      .set('method', method)
      .set('accountId', accountId)
      .set('outputFile', outputFile);

    const headers = { 'Content-Type': 'application/json' };

    if (method === 'get') {
      return this.http.get<any>(`${this.weatherContentDetailUrl}/call-api-aws-entry`, { params, headers });
    } else if (method === 'delete') {
      return this.http.delete<any>(`${this.weatherContentDetailUrl}/call-api-aws-entry`, { params, headers });
    } else {
      return this.http.put<any>(`${this.weatherContentDetailUrl}/call-api-aws-entry`, { autoUpdate }, { params, headers });
    }
  }

  uploadAfterSave(weatherContentId: string, folderPublishName: string, folderPublishNameOld: string) {
    const params = new HttpParams()
      .set('weatherContentId', weatherContentId)
      .set('folderPublishName', folderPublishName)
      .set('folderPublishNameOld', folderPublishNameOld);
    return this.http.post<any>(
      `${Constant.BACKEND_URL + Constant.PUBLISH_SERVICE_URL}api/publish/external-content/upload-after-save`,
      {},
      { params: params }
    );
  }
}
