import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constant } from 'app/config/constants';
import { WeatherContent } from 'app/model/entity/weather-content';
import { OpenWeatherContent } from 'app/model/entity/open-weather-content';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherContentService {
  /**
   * URL for weather-content API
   */
  private readonly weatherContentUrl = Constant.BACKEND_URL + Constant.BID_SERVICE_URL + 'api/weather-contents';

  constructor(private http: HttpClient) {}

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get all weather contents
   */
  getWeatherContents(): Observable<WeatherContent[]> {
    return this.http.get<WeatherContent[]>(`${this.weatherContentUrl}/get-all-weather-contents`);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content with full data template
   *
   * @param weatherContentId
   */
  getWeatherContentWithFullDataTemplateById(weatherContentId: Number): Observable<WeatherContent> {
    const params = new HttpParams().set('weatherContentId', weatherContentId + '');
    return this.http.get<WeatherContent>(`${this.weatherContentUrl}/get-news-content-with-full-data-template-by-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * get weather content with full data template
   *
   * @param weatherContentId
   */
  getOpenWeatherContentWithFullDataTemplateById(weatherContentId: Number): Observable<WeatherContent> {
    const params = new HttpParams().set('weatherContentId', weatherContentId + '').set('isPublish', 'false');
    return this.http.get<WeatherContent>(`${this.weatherContentUrl}/get-open-content-with-full-data-template-by-id`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * add weather content
   *
   * @param weatherContent
   */
  addWeatherContent(weatherContent: WeatherContent): Observable<WeatherContent> {
    return this.http.post<WeatherContent>(`${this.weatherContentUrl}/add`, weatherContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * edit weather content
   *
   * @param weatherContent
   */
  editWeatherContent(weatherContent: WeatherContent): Observable<WeatherContent> {
    return this.http.put<WeatherContent>(`${this.weatherContentUrl}/edit`, weatherContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * delete weather content
   *
   * @param weatherContentId
   */
  deleteWeatherContent(weatherContentId: Number) {
    const params = new HttpParams().set('weatherContentId', weatherContentId + '');
    return this.http.delete(`${this.weatherContentUrl}/delete`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * delete weather content
   *
   * @param weatherContentId
   */
  deleteOpenWeatherContent(weatherContentId: Number, folderPublishName: string) {
    const params = new HttpParams().set('weatherContentId', weatherContentId + '').set('folderPublishName', folderPublishName + '');
    return this.http.delete(`${this.weatherContentUrl}/delete-open-weather-content`, { params });
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * change template weather content
   *
   * @param weatherContent
   */
  changeTemplateForWeatherContent(weatherContent: WeatherContent) {
    return this.http.post(`${this.weatherContentUrl}/change-template`, weatherContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * change template weather content
   *
   * @param weatherContent
   */
  changeTemplateForOpenWeatherContent(openWeatherContent: OpenWeatherContent) {
    return this.http.post(`${this.weatherContentUrl}/change-template-open-weather-content`, openWeatherContent);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * create output file
   *
   * @param template
   */
  createOutputFile(content: any) {
    return this.http.post(`${this.weatherContentUrl}/create-output-file`, content);
  }

  /**
   * Be used by EXTERNAL-CONTENT-MANAGER
   * create output file
   *
   * @param template
   */
  createOutputFileOpenWeather(content: any) {
    return this.http.post(`${this.weatherContentUrl}/create-output-file-open-weather`, content);
  }
}
